import React, { Component } from 'react';
import Content from '../../components/component_crypto/ContentLady';


class LadingPage extends Component {
    render() {
        return (
            <div className="main" style={{ backgroundImage: 'url("image_crypto/2917875.jpg ")' }}>

                <Content />

            </div>
        );
    }
}

export default LadingPage;