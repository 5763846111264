import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ContactLady from "./ContactLady";
import HeaderLady from "./HeaderLady";
import Price from "./Price";

const initData = {
  sub_heading: "EUTHENIA",
  heading: "Réinventer la finance comme jamais auparavant ",
  content: "",
};

class ContentLady extends Component {
  state = {
    initData: {},
  };
  componentDidMount() {
    this.setState({
      initData: initData,
    });
  }
  render() {
    return (
      <section className="hero-section">
        <HeaderLady />

        <div className="container" style={{ marginTop: '100px' }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6 col-lg-9 text-center">
              {/* Hero Content */}
              <div className="hero-content">
                <div className="intro text-center text-white mb-5">
                  <span className="intro-text">
                    {this.state.initData.sub_heading}
                  </span>
                  <h2 style={{ marginBottom: '30px', marginTop: '15px' }}><b>{this.state.initData.heading}</b></h2>
                  <p className="mt-4">

                    Échangez, GAGNEZ, JOUEZ et gagnez <b>un APY fixe de 0,000302549 % </b> avec le jeton AutoStaking d'origine. Un protocole DeFi novateur axé sur la communauté et l'innovation

                  </p>
                </div>
                {/* Buttons */}
                <div className="button-group">
                  <Link className="btn btn-primary" to="/swap">
                    Acheter maintenant
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Price />
        <ContactLady />
      </section>

    );
  }
}

export default ContentLady;
