import { RotateCcw, Settings, Plus } from 'react-feather';
import { Link } from 'react-router-dom';

const LiquidityComponent = () => {

    return (

        <section className='mt-4'>
            <div className="d-flex justify-content-center ">
                <div style={{ width: '500px' }}>
                    <div className="d-flex justify-content-between">
                        <Link className="nav-item nav-wallet text-white" to='/swap'>Echanger</Link>
                        <div className="nav-item nav-wallet text-white" style={{ background: "#314277bd" }}> Liquidité</div>
                        <Link className="nav-item nav-wallet text-white" to='/calculator'>Calculator</Link>
                    </div>
                    <div className="card mt-4" style={{ padding: '30px', border: "#0a95e8e6 solid", borderRadius: "40px" }}>
                        <div className="card-header">
                            <div className="d-flex justify-content-between text-white">
                                <div className=""><b>Votre liquidité</b> <br /> Supprimer la liquidité pour recevoir des jetons en retour </div>
                                <div className="d-flex justify-content-between"><RotateCcw className='cursorWallet' size={20} /> <Settings size={20} className='ml-2 cursorWallet' /></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='card ' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='text-center'>
                                    <span className='text-white'>Aucune liquidité trouvée.<br />
                                        Vous ne voyez pas un pool que vous avez rejoint ? <br/></span>
                                    <span className='text-success'>
                                    Trouver d'autres jetons LP
                                    </span> 

                                </div>

                            </div>

                        </div>
                        <div className='card-footer'>
                            <div className='text-center'>
                                <button className='btn btn-primary'><Plus size={20} className='ml-2 cursorWallet' /> Ajouter de la liquidité</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}
export default LiquidityComponent;