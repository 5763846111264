import { useState } from "react";
import { RotateCcw, Settings } from "react-feather";
import { Link } from "react-router-dom";

const SwapComponent = () => {
  const [provider, setProvider] = useState(undefined);
  const [signer, setSigner] = useState(undefined);
  const [signerAddress, setSignerAddress] = useState(undefined);

  // Make the request and print the formatted response:
  // axios(config)
  // .then((response) => console.log(response["data"]["result"]))
  // .catch((error) => console.log("error", error));

  return (
    <section className="mt-4">
      <div className="d-flex justify-content-center ">
        <div style={{ width: "500px" }}>
          <div className="d-flex justify-content-between">
            <div
              className="nav-item nav-wallet text-white"
              style={{ background: "#314277bd" }}
            >
              Echanger
            </div>
            <Link className="nav-item nav-wallet text-white" to="/liquidity">
              {" "}
              Liquidity
            </Link>
            <Link className="nav-item nav-wallet text-white" to="/calculator">
              Calculator
            </Link>
          </div>
          <div
            className="card mt-4"
            style={{
              padding: "30px",
              border: "#0a95e8e6 solid",
              borderRadius: "40px",
            }}
          >
            <div className="card-header">
              <div className="d-flex justify-content-between text-white">
                <div className="">
                  <b>Swap</b> <br /> Fast, Secure, KYC-Free
                </div>
                <div className="d-flex justify-content-between">
                  <RotateCcw className="cursorWallet" size={20} />{" "}
                  <Settings size={20} className="ml-2 cursorWallet" />
                </div>
              </div>
            </div>
            <div className="card-body">
              <div
                className="card "
                style={{
                  background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="">
                    <div className="text-white">From:</div>
                    <div className="">
                      <input
                        placeholder="0.0"
                        type="text"
                        className="input-calculator"
                      ></input>{" "}
                    </div>
                  </div>
                  <div className="">
                    <div className="text-white">Balance:0</div>
                    <div className="">
                      <span className="text-danger">Max</span> BNB
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card mt-4"
                style={{
                  background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="">
                    <div className="text-white">To</div>
                    <div className="">
                      <input
                        placeholder="0.0"
                        type="text"
                        className="input-calculator"
                      ></input>
                    </div>
                  </div>
                  <div className="">
                    <div className="text-white">Balance:0</div>
                    <div className="">
                      <span className="text-danger">Max</span> BNB
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="text-center">
                <button className="btn btn-primary">Enter an amount</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SwapComponent;
