import React, { Component } from 'react';


class ContactLady extends Component {

    state = {
        data: {},
        socialData: [
            {id: 1, link: "https://www.facebook.com/", icon: "icon-social-facebook"},
            {id: 2, link: "https://twitter.com/", icon: "icon-social-twitter"},
            {id: 3, link: "https://www.linkedin.com/", icon: "icon-social-linkedin"},
            {id: 4, link: "https://www.reddit.com/", icon: "icon-social-reddit"},
            {id: 5, link: "https://discord.com/", icon: "icon-social-vkontakte"},
            { id: 6, link: "https://www.youtube.com/", icon: "icon-social-youtube"}
        ],
        widgetData: []
    }
    render() {
        return (
            <div className="container">
            <br></br>
                <div className='row items'>
                    <div className='col-12 col-md-6 col-lg-4'>
                        <h4>SUIVEZ-NOUS ICI   > </h4>
                    </div>
                    <div className=" col-12 col-md-6 col-lg-4 social-icons d-flex justify-content-center my-4">
                        {this.state.socialData.map((item, idx) => {
                            return (
                                <a key={`fsd_${idx}`} className="facebook" href={item.link} target="blank">
                                    <i className={item.icon} />
                                    <i className={item.icon} />
                                </a>
                            );
                        })}
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 d-flex justify-content-end '>
                        <h4>Euthenia CRYPTO </h4>
                    </div>
                </div>

            </div>
        );
    }
}
export default ContactLady;