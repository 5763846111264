import { useText } from "../../ComponentProvider";
import { ArrowLeft, ArrowRight, LogIn } from 'react-feather';
import { Icon } from '@iconify/react';
import { useState } from "react";
import moment from 'moment'


const LoterieGame = () => {
    const context = useText();
    const [prix, setprix] = useState(0)

    const handleChange = (event) => {

        const target = event.target.value;
        let value = parseInt(target)
        if (value) {
            value = value * 18345.26401;
            setprix(value);
        }
        if (target.length === 0) {
            setprix(0);
        }
    }
    return (
        <section>
            <div className="container">

                <div className="card " >
                    <div className="row">
                        <div className="texte-center col-12 col-md-6 col-lg-6">
                            <div className="d-flex justify-content-center">
                                <div>
                                    <div className="text-center">Lotterty du Euthenia</div>
                                    <div className='prix-gagner mt-2'>
                                        <font style={{ verticalAlign: 'inherit' }}>$ </font>
                                        <font style={{ verticalAlign: 'inherit' }}>3 00000 </font>
                                    </div>
                                    {!context.value ? <div className="mt-4">
                                        <button className="button-loterry" data-toggle="modal" data-target="#wallet_connect">Connecter au  Wallet</button>
                                    </div> : null}

                                </div>
                            </div>
                        </div>
                        <div className="ticket-loterry col-12 col-md-6 col-lg-6">
                            <div className=" d-flex justify-content-center">
                                <div>
                                    Obtenez vos billets maintenant
                                    <div className="d-flex justify-content-between mt-4">
                                        <div className="mr-2">
                                            <div className="d-flex justify-content-center"><div className="chrono-loterry">{context.heure}</div></div>
                                            <div className="text-center" style={{ fontSize: '12px' }}>Heure</div>
                                        </div>
                                        <div className="text-white font-weight-bold">:</div>
                                        <div className="mr-2">
                                            <div className="d-flex justify-content-center"><div className="chrono-loterry">{context.min}</div></div>
                                            <div className="text-center" style={{ fontSize: '12px' }}>Minutes</div>
                                        </div>
                                        <div className="text-white font-weight-bold">:</div>
                                        <div className="">
                                            <div className="d-flex justify-content-center"><div className="chrono-loterry">{context.sec}</div></div>
                                            <div className="text-center" style={{ fontSize: '12px' }}>Secondes</div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="p-4">

                                    <div className="ticket">
                                        <div><b>$5</b></div>
                                        <div> En EUTHENIA par ticket</div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div data-toggle="modal" data-target="#acheter_ticket" className="button-ticket">Acheter des billets</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="card" style={{ border: "solid 1px #1c1cb58c" }}>
                            <div className="d-flex justify-content-between">
                                <div className="text-center">
                                    Gains de loterie
                                </div>
                                <div className="text-center"><button className="card bg-secondary text-white ml-2" style={{ height: '65px', cursor: 'not-allowed' }}>Se desister</button></div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 billet">
                        <div className="card" style={{ border: "solid 1px #f4f40d52", }}>
                            <div className="d-flex justify-content-between">
                                <div className="text-center">
                                    Vos billet
                                </div>
                                <button className="card bg-warning text-white" data-toggle="modal" data-target="#acheter_ticket" style={{ height: '65px' }}><b>Acheter vos billets</b></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className=" col-12 col-md-6 col-lg-4 text-center"><h3>Rondes terminées</h3></div>
                    <div className=" col-12 col-md-6 col-lg-4 d-flex" style={{ height: '65px' }}>
                        <button className="card bg-white"><b>Tous l'histoire</b></button>
                        <button className="card text-white"><b>Votre histoire</b></button>
                    </div>

                </div>
                <div className="card mt-4" >
                    <div className="d-flex justify-content-between text-white">
                        <div className="">Tour 0</div>
                        <div className="d-flex "><ArrowLeft className="mr-4" /> <ArrowRight className="mr-4" /> <LogIn /></div>
                    </div>
                    <div className="">
                        Tire le {moment().format('lll')}
                    </div>

                    <div className="text-center"><h3>Numéro gagnant </h3></div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card" style={{
                                background: "#060606",
                                border: "solid 1px #556584"
                            }}>
                                <div className="d-flex justify-content-between">
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    <div className="number" style={{}}><b className="number-span">7</b></div>
                                    
                                </div>

                            </div>

                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="">

                            </div>
                            <div className="card">11</div>

                        </div>



                    </div>

                </div>

            </div>

            <div className="modal fade" id="acheter_ticket" tabIndex="-1" role="dialog"
                aria-labelledby="wallet_accountTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content contentModal">
                        <div className="modal-header text-center row">
                            <div className="col-6 col-md-6 col-lg-6 justify-content-start"  >
                                <span className="intro-text">
                                    Achetez des billets
                                </span>
                            </div>
                            <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end ">
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>


                        </div>
                        <div className="modal-body" style={{ overflow: 'hidden' }}>
                            <div className="">
                                <div className="d-flex justify-content-between w-100">
                                    <div>Achetez</div>
                                    <div> Des billets <Icon icon="fe:ticket" size='10' color="blue" width="45" height="45" rotate={1} /></div>
                                </div>
                                <div className="card ticket-input" style={{ border: ((context.balance.length > 0 && parseFloat(context.balance) > 5) || context.balance.length === 0) ? ' solid 1px transparent' : 'solid 1px red' }}>
                                    <input placeholder='0.0' type="numeric" className='input-calculator' onChange={handleChange} style={{ textAlign: 'right' }}></input>
                                    <div className="d-flex justify-content-end">
                                        ~{prix === 0 ? '0.00' : prix} EUTH
                                    </div>
                                </div>
                                {((context.balance.length > 0 && parseFloat(context.balance) < 5)) ? <div style={{ color: 'red', textAlign: "right" }}>Balance EUTHENIA insuffisant </div> : null}

                                <div className="d-flex justify-content-between mt-4">
                                    <div className="">Cout(EUTH)</div>
                                    <div className="">0.000000 EUTHENIA</div>
                                </div>
                                <div className="d-flex justify-content-between mt-2">
                                    <div className="">0% Remise en gros</div>
                                    <div className="">~ 0 EUTHENIA</div>
                                </div>
                                <div className="ligne"></div>
                                <div className="d-flex justify-content-between mt-2">
                                    <div className="">Tu payes</div>
                                    <div className="">~ 0.00000 EUTHENIA</div>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    {context.value ?
                                        <button className="btn w-100" >
                                            Activer
                                        </button> :
                                        <button className="btn w-100" data-toggle="modal" data-target="#wallet_connect" data-dismiss="modal" aria-label="Close">
                                            Connecter au Wallet
                                        </button>}
                                </div>
                                <div className="mt-4">
                                    <p>
                                        "Acheter instantanément" choisit des numéros aléatoires, sans doublons parmi vos billets. Les prix sont fixés avant le début de chaque tour, égal à 5 ​​$ à ce moment-là. Les achats sont définitifs.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
}
export default LoterieGame;