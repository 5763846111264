import { Component } from "react";
import Header from "../Header/Header";
import Footer from '../Footer/Footer';
import ModalSearch from '../Modal/ModalSearch';
import ModalMenu from '../Modal/ModalMenu';
import Dasboard from "./Dasboard";


class Home extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Dasboard/>
                <Footer />
                <ModalSearch />
                <ModalMenu />
            </div>
        )
    }
}
export default Home;