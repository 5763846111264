import LoterieGame from "../../components/component_crypto/LoterieGame";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ModalMenu from "../../components/Modal/ModalMenu";
import ModalSearch from "../../components/Modal/ModalSearch";


const LoterieTheme = () => {
    return (
        <div className="main">
            <Header />
           <LoterieGame/>
            <Footer />
            <ModalSearch />
            <ModalMenu />
        </div>
    )

}
export default LoterieTheme;