import React from 'react';
import { Link } from 'react-router-dom';

const ModalMenu = () => {
    return (
        <div id="menu" className="modal fade p-0">
            <div className="modal-dialog dialog-animated">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        Menus <i className="far fa-times-circle icon-close" />
                    </div>
                    <div className="menu modal-body">
                        <div className="row w-100">

                            <div className="items p-0 col-12 text-center" />
                            {/* <ul className="navbar-nav items mr-auto">
                                <li className="nav-item" >
                                    <Link to="/home" className="nav-link" >Page d'accueil</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link">Swych<i className="icon-arrow-down" /></a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <Link to="/swap" className="nav-link text-white" >Echanger</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/liquidity" className="nav-link text-white">Liquidité</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/calculator" className="nav-link text-white">Calculatrice</Link>
                                        </li>

                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="/customer" className="nav-link">Utilisateurs</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/account" className="nav-link">Compte</Link>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;