import { Component } from "react";
import { ValueContext } from "../../ComponentProvider";


class AccountComponent extends Component {
    // console.log()
    render() {
        const contexts = this.context;
        console.log('o', contexts.balance)

        return (
            <section>
                <div className="container">
                    <div className="row text-white">
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header ">
                                    <b>Total Gagné </b>
                                </div>
                                <div className="card-body">
                                    {contexts.value ?
                                        <div className="ml-4 ">
                                            <h4>${contexts.balance} USD</h4>
                                            0.000
                                        </div> : <div className="text-center">
                                            <div className="spinner-grow" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>}
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <b>Votre solde </b>
                                </div>
                                <div className="card-body">

                                    {contexts.value ?
                                        <div className="ml-4 ">
                                            <h4>${contexts.balance} USD</h4>
                                            0.000
                                        </div> : <div className="text-center">
                                            <div className="spinner-grow" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            <div className="spinner-grow" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>}

                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <b>  APY</b>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-12 col-md-12 col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <b> Vos revenus quotidiens </b>
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        <div className="spinner-grow" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-12">
                            <div className="card p-4">
                                <div className="d-flex justify-content-between mb-4">
                                    <div>
                                        <b>Montant de la récompense suivante:</b>
                                    </div>
                                    <div>00</div>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div>
                                        <b>Prochaine rebase dans:</b>
                                    </div>
                                    <div>00</div>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div>
                                        <b>Prochaine récompense :</b>
                                    </div>
                                    <div>00</div>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div>
                                        <b>Rendement de la prochaine récompense :</b>
                                    </div>
                                    <div>00</div>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <div>
                                        <b>% ROI (taux sur 30 jours) </b>
                                    </div>
                                    <div>00</div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <b>ROI $ (taux sur 30 jours)</b>
                                    </div>
                                    <div>test2</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </section>
        )
    }
}
AccountComponent.contextType = ValueContext;
export default AccountComponent;