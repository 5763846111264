import React, { Component } from "react";

const initData = {
    
    title: "Title",
    content: "Content",
};
class Price extends Component {

    state = {
        initData: {},
    };
    componentDidMount() {
        this.setState({
            initData: initData,
        });
    }
    render() {
        return (
            <div  style={{marginTop: '230px', marginBottom: '45px'}}>
                <div className="container">
                    <div className="row items">
                        {/* {this.state.initData.map((item, idx) => {
                            return ( */}
                                <div className="col-12 col-md-6 col-lg-3 item">
                                    <div className="card blog-card">
                                        <div className="blog-content">
                                            <a href="/blog-single">
                                                <h4>$0.0005</h4>
                                            </a>
                                            <p>Prix Euthenia</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3 item">
                                    <div className="card blog-card">
                                        <div className="blog-content">
                                            <a href="/blog-single">
                                                <h4>$12.83M</h4>
                                            </a>
                                            <p>Marketcap</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3 item">
                                    <div className="card blog-card">
                                        <div className="blog-content">
                                            <a href="/blog-single">
                                                <h4>102 483%</h4>
                                            </a>
                                            <p>APY fixe</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-3 item">
                                    <div className="card blog-card">
                                        <div className="blog-content">
                                            <a href="/blog-single">
                                                <h4>80K</h4>
                                            </a>
                                            <p> Titulaires Euthenia</p>
                                        </div>
                                    </div>
                                </div>
                            {/* );
                        })} */}

                    </div>

                </div>
            </div>
        )
    }
}
export default Price;