import React, { Component } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ModalSearch from '../../components/Modal/ModalSearch';
import ModalMenu from '../../components/Modal/ModalMenu';
import CustomerListe from '../../components/Customer/CustomerListe';

class Customers extends Component {
    render() {
        return (
            <div className="main">
                <Header/>
                <CustomerListe/>
                <Footer/>
                <ModalSearch />
                <ModalMenu />
            </div>
        );
    }
}

export default Customers;