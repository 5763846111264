import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Loterie extends Component {

    render() {
        return (
            <div className='container'>
                <div className='mt-4'>
                    <div className="d-flex justify-content-center">
                        <div className="card mt-4" style={{ border: "#0a95e8e6 solid", borderRadius: "40px" }}>
                            <div className="card-header">

                                <div className="text-center"><h3>La loterie d'Euthenia</h3></div>

                            </div>
                            <div className="card-body text-center">
                                {/* <div className='card '> */}
                                <div className=''>
                                    <span className='text-white'>Gagnez jusqu'à .<br />

                                    </span>
                                    <div className='prix-gagner'>
                                        <font style={{ verticalAlign: 'inherit' }}>$ </font>
                                        <font style={{ verticalAlign: 'inherit' }}>3 236.81 </font>
                                    </div>
                                    <h5>Tente ta chance!</h5>
                                    Jouer à la loterie euthenia vous donne une chance de gagner d'énormes prix.<br /> C'est simple et juste

                                </div>

                            </div>

                            {/* </div> */}
                            <div className='card-footer'>
                                <div className='text-center'>
                                    <button className='btn btn-primary'><Link to="/lottery">Acheter maintenant</Link> </button>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>

        );
    }
}

export default Loterie;