import { RotateCcw, Settings } from 'react-feather';
import { Link } from 'react-router-dom';


const CalculatorComponent = () => {

    return (
        <section className='mt-4'>
            <div className="d-flex justify-content-center ">
                <div style={{ width: '500px' }}>
                    <div className="d-flex justify-content-between">
                        <Link className="nav-item nav-wallet text-white" to='/swap'>Echanger</Link>
                        <Link className="nav-item nav-wallet text-white" to='/liquidity'> Liquidity</Link>
                        <div className="nav-item nav-wallet text-white" style={{ background: "#314277bd" }}>Calculator</div>
                    </div>
                    <div className="card mt-4" style={{ padding: '30px', border: "#0a95e8e6 solid", borderRadius: "40px" }}>
                        <div className="card-header">
                            <div className="d-flex justify-content-between text-white">
                                <div className=""><b>Calculatrice </b> <br /> Estimez votre retour. </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className='card ' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Montant EUTHENIA:</div>
                                        <div className=''> <input placeholder='0.0' type="text" className='input-calculator'></input> </div>

                                    </div>
                                    <div className=''>
                                        <div className='text-white'>Solde:0,00</div>
                                        

                                    </div>
                                </div>

                            </div>
                            <div className='card mt-4' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Prix d'achat:</div>
                                        <div className=''><input placeholder='0.0' type="text" className='input-calculator'></input> </div>

                                    </div>
                                    <div className=''>
                                        <div className='text-white'>0.0000</div>
                                    </div>
                                </div>

                            </div>
                            <div className='card mt-4' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Prix futur:</div>
                                        <div className=''> <input placeholder='0.0' type="text" className='input-calculator'></input> </div>

                                    </div>
                                    <div className=''>
                                        <div className='text-white'>0.0000</div>
                                    </div>
                                </div>

                            </div>
                            <div className='card mt-4' style={{ background: "rgba(40, 48, 70, 0.4) none repeat scroll 0% 0%" }}>
                                <div className='d-flex justify-content-between'>
                                    <div className=''>
                                        <div className='text-white'>Combien de jours?</div>
                                        <div className=''><input placeholder='0.0' type="text" className='input-calculator'></input> </div>

                                    </div>
                                    <div className=''>
                                        <div className=' d-flex justify-content-between text-white'>
                                            <div className='mr-1'>7</div>
                                            <div className='mr-1'>15</div>
                                            <div className='mr-1'>30</div>
                                            <div className='mr-1'>60</div>
                                            <div className='mr-1'>90</div>
                                            <div className='mr-1'>180</div>
                                            <div>365</div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className='card-footer'>
                            <div className='text-center'>
                                <button className='btn btn-primary'>Enter an amount</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}
export default CalculatorComponent;