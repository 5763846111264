/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useText } from "../../ComponentProvider";
import "../../components/component_crypto/css/cssCripto.css";
import { BigNumber, ethers } from "ethers";
import jsonWallet from "../../Data/jsonMetamask.json";
import Wallet from "../Wallet/Wallet";
import Url_base from "../../BaseUrl";
import axios from "axios";
import erc20abi from "../../erc20ABI.json";

const Header = () => {
  const context = useText();
  const [balance, setBalance] = useState("");
  const [meta, setMeta] = useState(false);
  const disconnect = () => {
    context.deconnecte();
  };

  const hexToDecimal = (hex) => parseInt(hex, 16);


  const btnhandler = async () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = "0xF590F76783BF782712DE1cD2FFa280bF24e54a0A";
      const erc20 = new ethers.Contract(contract, erc20abi, provider);

      const tokenName = await erc20.name();
      const tokenSymbol = await erc20.symbol();
      const totalSupply = await erc20.totalSupply();

      await provider.send("eth_requestAccounts", []);
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const balance = await erc20.balanceOf(signerAddress);

      const arrayContract = [tokenName, tokenSymbol, totalSupply,balance];
      const float = hexToDecimal(balance._hex)
      
      // const big = BigNumber.from(float);
      // console.log(arrayContract,big);

      // res[0] for fetching a first walletconst hexToDecimal = hex => parseInt(hex, 16);
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          setMeta(true);
          accountChangeHandler(res[0]);
        })
        .catch((er) => {
          setMeta(true);
          alert(er.message);
        });
      // if(!meta){
      //     alert('Configurer votre Metamask')
      // }
    } else {
      if (
        window.confirm(
          "Install metamask extension!! Confirme l'installation au https://metamask.io/download/"
        )
      ) {
        window.open("https://metamask.io/download/");
      }
    }
  };

  // getbalance function for getting a balance in
  // a right format with help of ethers
  const getbalance = (address) => {
    // Requesting balance method
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balances) => {
        // Setting balance
        setBalance(ethers.utils.formatEther(balances));
        var requete = {
          adresse: address,
          balance: ethers.utils.formatEther(balances),
        };
        context.connectMeta(address, ethers.utils.formatEther(balances));

        axios
          .post(`${Url_base}/adress`, requete)
          .then((res) => {})
          .catch((err) => console.log(err));
      });
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting a balance
    getbalance(account);
  };

  return (
    <>
      <header id="header">
        {/* Navbar */}
        <nav
          data-aos="zoom-out"
          data-aos-delay={800}
          className="navbar gameon-navbar navbar-expand"
        >
          <div className="d-flex justify-content-between w-100">
            {/* Logo */}
            <Link className="text-white" to="/home">
              {/* <b style={{ fontSize: '30px' }}> EUTHENIA </b> */}
              <img
                style={{ width: "250px" }}
                src="/image_crypto/logo.png"
                alt="Brand Logo"
              />
            </Link>

            {/* <div className="ml-auto" /> */}
            {/* Navbar Nav */}
            <ul className="navbar-nav items mr-auto">
              <li className="nav-item">
                <Link to="/home" className="nav-link">
                  Page d'accueil
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a href="#" className="nav-link">
                  Swych
                  <i className="icon-arrow-down" />
                </a>
                <ul className="dropdown-menu menu-acceuil">
                  <li className="nav-item">
                    <Link to="/swap" className="nav-link text-white">
                      Echanger
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/liquidity" className="nav-link text-white">
                      Liquidité
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/calculator" className="nav-link text-white">
                      Calculatrice
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a href="#" className="nav-link">
                  Produit
                  <i className="icon-arrow-down" />
                </a>
                <ul className="dropdown-menu menu-acceuil">
                  <li className="nav-item">
                    <Link to="/lottery" className="nav-link text-white">
                      Lottery
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <Link to="/customer" className="nav-link">
                  Utilisateurs
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/account" className="nav-link">
                  Compte
                </Link>
              </li>
            </ul>
            {/* Navbar Icons */}
            <ul className="navbar-nav icons">
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  data-toggle="modal"
                  data-target="#search"
                >
                  <i className="icon-magnifier" />
                </a>
              </li>
            </ul>
            {/* Navbar Toggler */}
            <ul className="navbar-nav toggle">
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-link"
                  data-toggle="modal"
                  data-target="#menu"
                >
                  <i className="icon-menu m-0" />
                </a>
              </li>
            </ul>
            {/* Navbar Action Button */}
            {context.value ? (
              <ul className="navbar-nav action">
                <li className="nav-item ml-2 dropdown">
                  <div className=" connect-addresse  btn ml-lg-auto btn-bordered-white">
                    <i className="icon-wallet mr-md-2" />
                    {context.adress}
                  </div>
                  <div className="dropdown-menu menu-connect">
                    {/* <div className="nav-item wallet-connect "> */}
                    <button
                      className="nav-item wallet-connect button-wallet"
                      data-toggle="modal"
                      data-target="#wallet_account"
                    >
                      Wallet
                    </button>
                    {/* </div> */}
                    <div className="nav-item wallet-connect1 wallet-connect">
                      <button
                        className="nav-item wallet-connect button-wallet"
                        data-toggle="modal"
                        data-target="#wallet_trasaction"
                      >
                        Transactions récentes
                      </button>
                      {/* <div className='mb-2'>
                                                Recent Transactions
                                            </div> */}
                    </div>
                    <div className="nav-item wallet-connect">
                      <div onClick={disconnect} className="mb-2">
                        Déconnecté
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav action">
                <li className="nav-item ml-2">
                  <button
                    type="button"
                    className="btn ml-lg-auto btn-bordered-white"
                    data-toggle="modal"
                    data-target="#wallet_connect"
                  >
                    <i className="icon-wallet mr-md-2" /> Connecter au Wallet
                  </button>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </header>

      <Wallet btnhandler={btnhandler} walletAddress={context.adress} />
    </>
  );
};

export default Header;
