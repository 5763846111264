import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// importing all the themes
import LadingPage from "../themes/themes_crypto/lading-page";
import Home from "../components/Home/Home";
import Customers from "../themes/themes_crypto/customer";
import Account from "../themes/themes_crypto/account";
import Liquidity from "../themes/themes_crypto/liquidity";
import Swap from "../themes/themes_crypto/swap";
import Calculator from "../themes/themes_crypto/calculator";
import LoterieTheme from "../themes/themes_crypto/LoterieTheme";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LadingPage />} />

            <Route exact path="/home" element={<Home />} />

            <Route exact path="/calculator" element={<Calculator />} />
            <Route exact path="/swap" element={<Swap/>} />
            <Route exact path="/liquidity" element={<Liquidity/>} />

            <Route exact path="/lottery" element={<LoterieTheme />} />

            <Route exact path="/customer" element={<Customers />} />

            <Route exact path="/account" element={<Account />} />

          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default MyRouts;
