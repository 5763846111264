import React, { Component } from "react";
import './css/cssCripto.css'

class HeaderLady extends Component {

    render() {
        return (
            <div className="d-flex justify-content-between headerLady text-white">
                <div className=" ">
                    <img style={{ width: '250px' }} src="/image_crypto/logo.png" alt="Brand Logo" />

                </div>
                {/* <div className="d-flex justify-content-end"> */}

                    <a className="btn ml-4" href="/home">
                        <i className="icon-rocket mr-2" />
                        Exécuter
                    </a>

                {/* </div> */}

            </div>
        )
    }
}
export default HeaderLady;