import React from "react";
// importing MyRouts where we located all of our theme
import MyRouts from "./routers/routes";
import { ComponentProvider } from "./ComponentProvider";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

function App() {
  const [value, setValue] = React.useState(false);
  const [adress, setAdresse] = React.useState("");
  const [balance, setBalance] = React.useState("");

  const connectMeta = (adr, balances) => {
    setValue(true);
    setAdresse(adr);
    setBalance(balances);
  };
  const deconnecte = () => {
    setValue(false);
    setBalance("");
  };

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");
  const [min, setMin] = useState("00");
  const [heure, setHeure] = useState("4");
  const [sec, setSec] = useState("00");
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
      setHeure(hours > 9 ? hours : "0" + hours);
      setMin(minutes > 9 ? minutes : "0" + minutes);
      setSec(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 14400);
    return deadline;
  };

  useEffect(() => {
    // axios
    //   .get(`https://api.db-ip.com/v2/free/self`)
    //   .then((res) => {
    //     console.log(res.data.ipAddress)
    //     localStorage.setItem('ip', res.data.ipAddress)
    //     localStorage.setItem('')
    //   })
    //   .catch((err) => console.log(err));

    clearTimer(getDeadTime());
  }, []);
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return (
    <ComponentProvider
      value={{
        value,
        adress,
        balance,
        timer,
        heure,
        min,
        sec,
        connectMeta,
        deconnecte,
      }}
    >
      <div>
        <MyRouts />
      </div>
    </ComponentProvider>
  );
}

export default App;
