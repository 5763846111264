import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ModalMenu from "../../components/Modal/ModalMenu";
import ModalSearch from "../../components/Modal/ModalSearch";
import SwapComponent from "../../components/Swych/SwapComponent";


const Swap = () => {
    return (
        <div className="main">
            <Header />
            <SwapComponent  />
            <Footer />
            <ModalSearch />
            <ModalMenu />
        </div>
    )

}
export default Swap;