import React from 'react'

const defaultValue = {}

const ValueContext = React.createContext(defaultValue)

const ComponentProvider = ({ children, value }) => {
    return <ValueContext.Provider value={value}>
        {children}
    </ValueContext.Provider>
}
const useText = () => React.useContext(ValueContext)
export { ComponentProvider, useText, ValueContext}