import React, { Component } from "react";
import jsonWallet from "../../Data/jsonMetamask.json";
import { ValueContext } from "../../ComponentProvider";
import { Copy } from 'react-feather';



class Wallet extends Component {
    state = {
        data: {},
        walletData: [],
        address: "",
        Balance: null,
        Alert: null
    };


    // usetstate for storing and retrieving wallet details
    componentDidMount() {
        var data = jsonWallet;
        // console.log(ethers.getNetwork("homestead"))
        this.setState({
            data: data,
            walletData: data.walletData,
        });
    }

    render() {
        return (

            // <section className="wallet-connect-area">
            <>
                <div className="modal fade" id="wallet_connect" tabIndex="-1" role="dialog"
                    aria-labelledby="wallet_connectTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                        <div className="modal-content contentModal">
                            <div className="modal-header text-center row">
                                <div className="col-6 col-md-6 col-lg-6 justify-content-start">
                                    <span className="intro-text">
                                        {this.state.data.sub_heading}
                                    </span>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end ">
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                            </div>
                            <div className="modal-body row">
                                {this.state.walletData.map((item, idx) => {
                                    let metamask = null;
                                    if (item.title === 'MetaMask') {
                                        metamask = true;
                                    }
                                    return (

                                        <div key={`wd_${idx}`} className="col-6 col-md-6 col-lg-6 item">
                                            {/* Single Wallet */}
                                            <div className="card m-1">
                                                <div onClick={metamask ? () => { this.props.btnhandler() } : null} data-dismiss='modal'
                                                    aria-label="Close" className='text-center' >
                                                    <img className="avatar-lg" src={item.img} alt="" />
                                                    <h6 className="mb-0">{item.title}</h6>

                                                </div>
                                            </div>
                                        </div>

                                    );
                                })}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="wallet_account" tabIndex="-1" role="dialog"
                    aria-labelledby="wallet_accountTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                        <div className="modal-content contentModal">
                            <div className="modal-header text-center row">
                                <div className="col-6 col-md-6 col-lg-6 justify-content-start">
                                    <span className="intro-text">
                                        Your wallet
                                    </span>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end ">
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>


                            </div>
                            <div className="modal-body card" style={{ overflow: 'hidden' }}>
                                <div className="w-100 card-header">
                                    <div className=" d-flex justify-content-between">
                                        <button className="wallet-button">Wallet</button>
                                        <div className="cursorWallet" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#wallet_trasaction">Transactions</div>

                                    </div>
                                </div>
                                <div className="card-body row">
                                    <div className=" col-12 text-success"><strong> YOUR ADDRESSE </strong></div>
                                    <div className="col-12 d-flex justify-content-between ">
                                        <div className="card wallet-adresse" onClick={() => { navigator.clipboard.writeText(this.props.walletAddress) }} style={{ cursor: 'copy'}}>{this.props.walletAddress}</div>
                                        <div onClick={() => { navigator.clipboard.writeText(this.props.walletAddress) }} style={{ cursor: 'pointer' }} ><Copy/></div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="card wallet-warning"><b>BNB Balance Low</b> <br />You need BNB for transaction fees.</div>
                                    </div>
                                    <div className="card col-12">
                                        <div className=" d-flex justify-content-between">
                                            <div className="">
                                                <div>BNB Balance</div>
                                                <div>CRYPTO Balance</div>
                                            </div>
                                            <div className="">
                                                <div>00</div>
                                                <div>00</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="wallet_trasaction" tabIndex="-1" role="dialog"
                    aria-labelledby="wallet_accountTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                        <div className="modal-content contentModal">
                            <div className="modal-header text-center row">
                                <div className="col-6 col-md-6 col-lg-6 justify-content-start">
                                    <span className="intro-text">
                                        Your wallet
                                    </span>
                                </div>
                                <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end ">
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>


                            </div>
                            <div className="modal-body card" style={{ overflow: 'hidden' }}>
                                <div className="w-100 card-header">
                                    <div className=" d-flex justify-content-between">
                                        <div className="cursorWallet" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#wallet_account">Wallet</div>
                                        <button className="wallet-button">Transactions</button>

                                    </div>
                                </div>
                                <div className="card-body row">
                                    <div className=" col-12 text-success"><strong>RECENT TRANSACTIONS</strong></div>
                                    <div className="col-12 text-center ">
                                        No recent transactions
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
Wallet.contextType = ValueContext;
export default Wallet;