import React, { Component } from 'react';
import axios from 'axios';
import Url_base from '../../BaseUrl';
import moment from 'moment';


class CustomerListe extends Component {
    state = {
        data: {},
        leaderboardData: []
    }
    componentDidMount(){
        axios.get(`${Url_base}/api/clients`)
            .then(res => {
                this.setState({
                    leaderboardData: res.data['hydra:member']
                })
                console.log(res.data['hydra:member'])
            })
        .catch(err => console.log(err))

        console.log(moment('2022-09-19T16:38:57+03:00').format('lll'))
    }
    render() {
        return (
            <section className="leaderboard-area">
                <div className="container" style={{ marginTop: '5rem' }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table token-content table-borderless">
                                    <thead>
                                        <tr className='text-center' >
                                            <th >Adresse</th>
                                            <th >Balance</th>
                                            <th>Last connect</th>
                                            <th> Create</th> 
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.leaderboardData.length === 0 ? <div className='text-center bg-secondary text-light'>No data</div> : 
                                        this.state.leaderboardData.map((item, idx) => {
                                            return (
                                                <tr className='text-center' key={`lbd_${idx}`}>
                                                    <td >{item.adresse}</td>
                                                    <td>{item.balance}</td>
                                                    <td>{moment(item.updatedAt).format('lll')}</td>
                                                    <td>{moment(item.createdAt).format('lll')}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </section>
        );
    }
}

export default CustomerListe;